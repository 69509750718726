.transactions-table {
  border-spacing: 0;
  width: 100%;
}

.transactions-table > thead {
  background: var(--light-gray);
}

.transactions-table > thead th {
  text-align: center;
  border: 0 solid var(--middle-gray);
  border-top-width: 1px;
  border-bottom-width: 1px;
  padding: 10px;
}

.transactions-table > tbody tr:hover {
  background: var(--light-gray);
}

.transactions-table > tbody td {
  text-align: center;
  border: 0 solid var(--middle-gray);
  border-bottom-width: 1px;
  padding: 10px;
}

.transactions-table > tbody a {
  color: var(--orange);
  text-decoration: none;
}

.transactions-table > tbody a:hover {
  color: var(--green);
}

.amount {
  white-space: nowrap;
}

.table-wrapper {
  overflow-y: auto;
}

.transactions-header {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .transactions-header {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1024px) {
  .transactions-header {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.transactions-total {
  padding-bottom: 20px;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
  text-align: center;
}
@media (min-width: 768px) {
  .transactions-total {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .transactions-total {
    grid-row-start: 1;
    grid-row-end: 2;
  }
}

.transactions-header > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.transactions-header .pagination {
  padding-bottom: 20px;
  justify-content: center;
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 2;
}
@media (min-width: 768px) {
  .transactions-header .pagination {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    justify-content: right;
  }
}
@media (min-width: 1024px) {
  .transactions-header .pagination {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 4;
  }
}

.transactions-header .bridge-now {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
}
@media (min-width: 768px) {
  .transactions-header .bridge-now {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media (min-width: 1024px) {
  .transactions-header .bridge-now {
    grid-column-start: 2;
    grid-column-end: 3;
  }
}

.transactions-footer > * {
  padding-top: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .transactions-footer {
    display: flex;
    justify-content: space-between;
  }
}

.transactions-size select {
  border-radius: 5px;
}

.transactions-footer .pagination {
  justify-content: center;
}
@media (min-width: 768px) {
  .transactions-footer .pagination {
    justify-content: right;
  }
}

.download-csv {
  color: inherit;
}
