:root {
  --orange: #ff7b00;
  --light-orange: #ffe2b8;
  --green: #3aac59;
  --light-gray: #f8f9fa;
  --middle-gray: #e7eaf3;
  --middle-dark-gray: #bbb;
  --dark-gray: #4a4f55;
  --red: #ff0000;
}

body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  color: var(--dark-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.25;
  background: var(--light-gray);
}

h1 {
  font-size: 21px;
  font-weight: 400;
  margin: 0 20px 20px;
}

input[type="text"] {
  font-family: inherit;
  font-size: inherit;
  padding: 10px;
  border: 1px solid var(--middle-gray);
}
input[type="text"].input-error:focus,
input[type="text"].input-error {
  border-color: var(--red);
}
input[type="text"]:focus,
select:focus {
  outline: none;
}

input[type="button"],
.button {
  font-family: inherit;
  font-size: inherit;
  padding: 15px 30px;
  border: 0;
  border-radius: 100px;
  background: var(--green);
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

table {
  border-spacing: 0;
}

select {
  border: 1px solid var(--green);
  padding: 5px 10px;
  border-radius: 100px;
  background: transparent;
  cursor: pointer;
}

.relative {
  position: relative;
}

.overflow-y-auto {
  overflow-y: auto;
}
