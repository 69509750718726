.label {
  display: inline-block;
  color: white;
  border-radius: 5px;
  padding: 3px 5px 2px;
  white-space: nowrap;
}

.label.waiting-for-attestations {
  background: red;
  color: white;
}

.label.waiting-for-execution {
  background: orange;
}

.label.executed {
  background: green;
}
