.generate-table {
  border-spacing: 0;
  width: 100%;
}

.generate-table > tbody th {
  width: 30%;
  text-align: left;
  background: var(--light-gray);
  border: 0 solid var(--middle-gray);
  border-bottom-width: 1px;
  padding: 10px;
}
.generate-table > tbody tr:first-child th {
  border-top-width: 1px;
}

.generate-table > tbody td {
  border: 0 solid var(--middle-gray);
  border-bottom-width: 1px;
  padding: 10px;
}
.generate-table > tbody tr:first-child td {
  border-top-width: 1px;
}
.generate-table > tbody input {
  width: calc(100% - 20px);
}

.generate-button {
  padding: 20px 0;
  text-align: center;
}

.generate-note {
  padding-bottom: 10px;
}

@media (max-width: 1024px) {
  .generate-table > tbody th,
  .generate-table > tbody td {
    display: block;
    width: auto;
  }
}
