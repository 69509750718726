.pagination {
  display: flex;
  gap: 3px;
}

.pagination > a {
  border: 1px solid var(--green);
  padding: 5px 10px 3px;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-size: 13px;
}

.pagination > a[disabled] {
  border-color: var(--middle-gray);
  cursor: default;
  color: var(--middle-dark-gray);
}

.pagination > a[disabled].pagination-pages {
  border-color: var(--dark-gray);
  color: var(--dark-gray);
}
