.page {
  padding: 20px;
}

.page-content {
  background: #fff;
  border: 1px solid var(--middle-gray);
  border-radius: 8px;
  padding: 20px;
}

.page-headline {
  display: flex;
  font-size: 21px;
  font-weight: 400;
}

.page-headline a {
  color: inherit;
  text-decoration: none;
  margin: 0 20px 20px;
}

.page-info {
  margin-bottom: 10px;
  border: 1px solid var(--orange);
  padding: 10px 20px;
  border-radius: 8px;
  background: var(--light-orange);
}
