.transaction-table {
  width: 100%;
}

.transaction-table > tbody > tr > th {
  text-align: left;
  background: var(--light-gray);
  border: 0 solid var(--middle-gray);
  border-bottom-width: 1px;
  padding: 10px;
}
.transaction-table > tbody > tr:first-child > th {
  border-top-width: 1px;
}

.transaction-table > tbody > tr > td {
  border: 0 solid var(--middle-gray);
  border-bottom-width: 1px;
  padding: 10px;
}
.transaction-table > tbody > tr:first-child > td {
  border-top-width: 1px;
}

.transaction-table > tbody a {
  color: var(--orange);
  text-decoration: none;
}

.transaction-table > tbody a:hover {
  color: var(--green);
}

.validators-table > thead > tr > th {
  text-align: left;
}

.validators-table > thead > tr > *:not(:last-child),
.validators-table > tbody > tr > *:not(:last-child) {
  padding-right: 20px;
}
